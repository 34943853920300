<template>
  <questionnaire-base-modal :title="title" :name="name" @before-open="onBeforeOpen">
    <template #main-content>
      {{ errorMessage }}
    </template>

    <template #actions>
      <u-button
        class="photo-validation-error-modal__button"
        kind="primary"
        @click="onConfirm(true)"
        >{{ $t('validationMessage.action.retake') }}</u-button
      >
    </template>
  </questionnaire-base-modal>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnaireBaseModal from '@/modules/questionnaire/components/dialogs/QuestionnaireBaseModal';

export default {
  name: 'PhotoValidationErrorModal',
  components: { QuestionnaireBaseModal, UButton },
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      onConfirm: () => {}
    };
  },
  methods: {
    onBeforeOpen({ params }) {
      const { onClose } = params || {};

      this.onConfirm = onClose;
    }
  }
};
</script>

<style lang="scss" scoped>
.photo-validation-error-modal {
  &__button {
    margin: 0 auto;
  }
}
</style>
